<template>
  <div>
    <b-row v-show="webcamSetup" align-h="center" class="pt-4">
      <b-col cols="10" class="px-0">
        <b-overlay
          opacity="1"
          bg-color="black"
          spinner-variant="primary"
          :show="!videoLoaded"
        >
          <div>
            <!-- VIDEO PLAYER -->
            <b-overlay :show="countdown >= 0" opacity="1" bg-color="black">
              <b-row class="no-gutters justify-content-center">
                <video
                  preload
                  id="video-player"
                  ref="videoPlayer"
                  :src="videoSource"
                ></video>
              </b-row>

              <template v-slot:overlay>
                <h1 class="text-center text-white font-weight-bold">
                  {{ $t("page.viewer.videoStartsIn") }}{{ countdown }}
                </h1>
              </template>
            </b-overlay>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  emits: ["startRecording", "stopRecording", "finishedRecording"],
  data() {
    return {
      videoSource: "",
      duration: 15,
      countdown: 3,
      videoLoaded: false,
      interval: 1,
    };
  },
  computed: {
    ...mapGetters("stimulus", {
      stimulus: "getStimulus",
      questions: "getQuestions",
    }),
    ...mapGetters("settings", {
      environment: "getEnvironment",
      respondentId: "getRespondentId",
      webcamSetup: "getWebcamSetup",
    }),
  },
  mounted() {
    this.startWatching();
  },
  methods: {
    startRecording(stimulus) {
      this.$emit("startRecording", {
        position: 0,
        stimulus,
      });
    },

    stopRecording(stimulus) {
      this.$emit("stopRecording", { stimulus });
    },

    finishedRecording() {
      this.$emit("finishedRecording");
    },

    async startWatching() {
      for (let index = 0; index < this.stimulus.length; ++index) {
        await this.setupVideo(this.stimulus[index]);
      }
      this.finishedRecording();
    },

    setupVideo(stimulus) {
      return new Promise((resolve) => {
        this.videoLoaded = false;
        this.countdown = 3;
        this.videoSource = "";
        this.images = [];

        this.videoSource = stimulus.src;
        this.$nextTick(() => {
          const videoPlayer = this.$refs.videoPlayer;

          this.playVideo(stimulus, videoPlayer).then(() => resolve());
        });
      });
    },

    playVideo(stimulus, videoPlayer) {
      return new Promise((resolve) => {
        const startVideo = () => {
          this.videoLoaded = true;
          this.duration = this.$refs.videoPlayer.duration - 1;
          const interval = setInterval(() => {
            if (this.countdown === 0) {
              this.startRecording(stimulus);
              videoPlayer.play();
              clearInterval(interval);
            }
            this.countdown--;
          }, 1000);
        };

        const stopVideo = () => {
          this.stopRecording(stimulus);
          setTimeout(() => {
            resolve();
          }, 1000);

          this.removeVideoListeners(videoPlayer, startVideo, stopVideo);
        };

        this.addVideoListeners(videoPlayer, startVideo, stopVideo);
      });
    },

    addVideoListeners(videoPlayer, startVideo, stopVideo) {
      videoPlayer.addEventListener("canplaythrough", startVideo);
      videoPlayer.addEventListener("ended", stopVideo);
    },

    removeVideoListeners(videoPlayer, startVideo, stopVideo) {
      videoPlayer.removeEventListener("canplaythrough", startVideo);
      videoPlayer.removeEventListener("ended", stopVideo);
    },
  },
};
</script>

<style scoped>
#video-player {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 85vh;
}

.overlay {
  position: absolute;
  width: 150px;
  height: 200px;
  border: 1px solid white;
  opacity: 0.2;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
